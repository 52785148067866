import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateTime } from 'luxon';

export const parsePhone = (phone: string, country?: CountryCode): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  if (phoneNumber) {
    return phoneNumber.format('NATIONAL');
  }
  return '';
};

export const omitString = (text: string, number: number): string => {
  return text.length > number ? text.slice(0, number) + '…' : text;
};

export const formatPhoneNumber = (
  phone: string,
  country?: CountryCode
): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, country);

  if (phoneNumber) {
    return phoneNumber.number;
  }

  return '';
};

export const formatToDate = (dateTime: string): string => {
  const date = DateTime.fromISO(dateTime, { zone: 'Asia/Tokyo' }).toFormat(
    'yyyy年MM月dd日'
  );
  return date == 'Invalid DateTime' ? '' : date;
};

export const formatToDateTime = (dateTime: string): string => {
  return DateTime.fromISO(dateTime, { zone: 'Asia/Tokyo' }).toFormat(
    'yyyy年MM月dd日 HH時mm分'
  );
};

export const formatByteToMB = (byte: number): number => {
  return Math.ceil((byte / (1024 * 1024)) * 10) / 10;
};

export const remainingDate = (dateTime: string | undefined): string => {
  if (!dateTime) {
    return '';
  }

  const remain = DateTime.fromISO(dateTime)
    .plus({ days: 7 })
    .diffNow('days').days;
  return (
    DateTime.fromISO(dateTime, { zone: 'Asia/Tokyo' })
      .plus({ days: 7 })
      .toFormat('yyyy年MM月dd日') + `（残 ${Math.ceil(remain)}日）`
  );
};

export const remainingDateWarning = (dateTime: string | undefined): string => {
  if (!dateTime) {
    return '';
  }
  const remaining = DateTime.fromISO(dateTime, { zone: 'Asia/Tokyo' }).diffNow(
    'days'
  ).days;
  return remaining <= 3 ? 'red--text' : '';
};

export const formatToHtml = (text: string): string => {
  return text.replace(/\n/g, '<br>');
};

export const removeLastPdfExtension = (s: string): string => {
  if (s.toLowerCase().endsWith('.pdf')) {
    return s.slice(0, -4);
  }
  return s;
};
