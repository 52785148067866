import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { FormItem } from '@/interfaces/lib/model';
import { MAX_FILE_SIZE } from '@/lib/constant';
import {
  companyNamePattern,
  departmentNamePattern,
  ipV4Regex,
  ipV6Regex,
  nullCharacterRegex,
  patternNoComma,
  patternNumberAndAlphabetsAndHyphen,
  patternNumberAndHyphen,
  patternPassword,
  patternPasswordForLogin,
  patternPdfExtension,
  positionNamePattern,
  sqlSelectRegex,
  patternEmailAddress,
  patternFreeEmailDomain,
} from '@/lib/regex';

export const required = (
  value: string | number | boolean | undefined
): boolean | string => {
  const v = typeof value === 'number' ? value.toString(10) : value;
  return !!v || '必須';
};
export const requiredEn = (
  value: string | number | boolean | undefined
): boolean | string => {
  const v = typeof value === 'number' ? value.toString(10) : value;
  return !!v || 'Required';
};
export const requiredSelection = (
  value: Array<string | number | boolean | undefined>
): boolean | string => value.length > 0 || '必須';
export const selectBool = (
  value: string | number | boolean | undefined
): boolean | string => value !== undefined || '必須';
export const fileSize = (file) =>
  !file ||
  !(file.size > MAX_FILE_SIZE) ||
  'File size should be less than 10 MB!';
export const isRightEmailAddress = (value: string): boolean | string =>
  !!value.match(patternEmailAddress) ||
  'メールアドレスの入力形式が間違っています。正しいメールアドレスを入力してください。';
export const isNotFreeEmailDomain = (value: string): boolean | string =>
  !!value.match(patternFreeEmailDomain) ||
  'ご入力いただいたメールアドレスは無効です。独自ドメインのメールアドレスを使用してください。';
export const isRightLengthPassword = (value: string): boolean | string =>
  !!value.match(patternPassword) ||
  '8文字以上かつ半角英大文字・半角英小文字・半角数字・記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)をそれぞれ最低1回以上使用してください';
export const isRightLengthPasswordForLogin = (
  value: string
): boolean | string =>
  !!value.match(patternPasswordForLogin) ||
  '8文字以上の大小半角英数字記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)で入力してください';
export const isComfirmed = (
  input: string,
  inputComfirmation: string,
  text: string
): boolean | string => input == inputComfirmation || text;
export const sqlSelect = (value: string): boolean | string =>
  !!sqlSelectRegex.test(value) || 'SELECT文以外は使用できません';
// eslint-disable-next-line
export const phoneNumber = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  const phoneNumber = parsePhoneNumberFromString(value, 'JP');
  return (
    (phoneNumber && phoneNumber.isValid()) ||
    '半角数字（半角ハイフンなし）で入力してください。半角数字＋半角ハイフンへ自動変換されます。'
  );
};

export const phoneNumberEn = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  const phoneNumber = parsePhoneNumberFromString(value, 'JP');
  return (
    (phoneNumber && phoneNumber.isValid()) ||
    'Please enter in half-width numbers (without half-width hyphens). It will be automatically converted to half-width numbers + half-width hyphens.'
  );
};

export const isCompanyName = (value: string): boolean | string =>
  !!value.match(companyNamePattern) ||
  '企業名が無効です。企業名には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。';

export const isMeaningfulCompanyName = (value: string): boolean | string =>
  !(value === '-' || value === 'ー' || value === 'なし' || value === '個人') ||
  '「-（ハイフン）」「ー（ハイフン）」「なし」「個人」などの企業名は無効です。正しい企業名を入力してください。';

export const isCompanyNameEn = (value: string): boolean | string =>
  !!value.match(companyNamePattern) ||
  'Company name is invalid. Please use alphabet number. for company name (special characters, etc. cannot be used at the beginning of the sentence).';

export const isDepartmentName = (value: string): boolean | string =>
  value === '' ||
  !!value.match(departmentNamePattern) ||
  '部署名が無効です。部署名には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。';

export const isDepartmentNameEn = (value: string): boolean | string =>
  value === '' ||
  !!value.match(departmentNamePattern) ||
  'Department name is invalid. Please use alphabet number. for department name (special characters, etc. cannot be used at the beginning of the sentence).';

export const isPositionName = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  return (
    !!value.match(positionNamePattern) ||
    '役職・職位が無効です。役職・職位には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。'
  );
};

export const isPositionNameEn = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  return (
    !!value.match(positionNamePattern) ||
    'Position name is invalid. Please use alphabet number. for position name (special characters, etc. cannot be used at the beginning of the sentence).'
  );
};

export const isNumberAndHyphen = (value: string): boolean | string =>
  !!value.match(patternNumberAndHyphen) ||
  '半角英数字とハイフンのみ使用可能です';

export const isNumberAndAlphabetsAndHyphen = (
  value: string
): boolean | string =>
  !!value.match(patternNumberAndAlphabetsAndHyphen) ||
  '半角英数字とハイフンのみ使用可能です';
export const isNumberAndHyphenArray = (
  value: Array<string>
): boolean | string => {
  let valid = true;

  for (const v of value) {
    if (!v.match(patternNumberAndHyphen)) {
      valid = false;
      break;
    }
  }

  return valid || '半角数字とハイフンのみ使用可能です';
};
export const isNumberAndAlphabetsAndHyphenArray = (
  value: Array<string>
): boolean | string => {
  let valid = true;

  for (const v of value) {
    if (!v.match(patternNumberAndAlphabetsAndHyphen)) {
      valid = false;
      break;
    }
  }

  return valid || '半角英数字とハイフンのみ使用可能です';
};

export const isIpAddress = (value: string): boolean | string => {
  if (new RegExp(`(?:^${ipV4Regex}$)|(?:^${ipV6Regex}$)`).test(value)) {
    return true;
  } else {
    return 'IPアドレスの形式が正しくありません';
  }
};

export const hasNoNullText = (value: string): boolean | string => {
  if (new RegExp(nullCharacterRegex).test(value)) {
    return '有効でないスペースが含まれています。手入力で再度入力してください。';
  } else {
    return true;
  }
};
export const hasNoNullTextArray = (value: Array<string>): boolean | string => {
  for (const v of value) {
    if (new RegExp(nullCharacterRegex).test(v)) {
      return '有効でないスペースが含まれています。手入力で再度入力してください。';
    }
  }

  return true;
};
export const hasNoNullTextItem = (value: FormItem): boolean | string => {
  if (new RegExp(nullCharacterRegex).test(value.text)) {
    return '有効でないスペースが含まれています。手入力で再度入力してください。';
  }

  return true;
};

export const isFloat = (value: string | number | undefined): boolean | string =>
  !!String(value).match(/^[-]?\d+(\.\d+)?$/) ||
  '半角数字(小数を含む)のみ使用可能です';
export const isFloatOrNull = (
  value: string | number | undefined
): boolean | string => {
  if (value == null) {
    return true;
  }
  return (
    !!String(value).match(/^[-]?\d+(\.\d+)?$/) ||
    !!value == null ||
    '半角数字(小数を含む)のみ使用可能です'
  );
};

export const hasPdfExtension = (value: string): boolean | string => {
  if (new RegExp(patternPdfExtension).test(value)) {
    return true;
  } else {
    return '拡張子が.pdfではありません';
  }
};

export const hasNoComma = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  return !!value.match(patternNoComma) || 'カンマ（,）は使用できません。';
};
