import { ref } from 'vue';

import { Department } from '@/interfaces/model/general/department';
import { User } from '@/interfaces/vuex/user';
import { store } from '@/store/index';
import { readCompany } from '@/store/main/getters';
import {
  dispatchGetCurrentUser,
  dispatchUpdateCurrentUser,
  dispatchCreateCompanyUser,
  dispatchDeleteCompanyUser,
  dispatchGetCompanyUser,
  dispatchRegenerateActivationMail,
  dispatchUpdateCompanyUser,
} from '@/store/main/actionsMain';
import { readUserProfile } from '@/store/main/getters';

export interface CompanyUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  departments: Department[];
  position: string;
  phone: string;
  isSubscribe: boolean;
  roleId: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUserComposable = () => {
  const isLoading = ref<boolean>(false);
  const user: User = { ...readUserProfile(store) };
  const users = ref<User[]>([]);

  const getCompanyUser = async (): Promise<void> => {
    users.value.splice(0);

    const response = await dispatchGetCompanyUser(store, {
      companyId: readCompany(store)['uuid'],
    });
    if (response) {
      users.value.push(
        ...response.data.map((x) => {
          return {
            uuid: x.uuid,
            first_name: x.first_name,
            last_name: x.last_name,
            middle_name: x.middle_name,
            email: x.email,
            phone: x.phone,
            departments: x.departments,
            position: x.position,
            company_id: x.company_id,
            role_id: x.role_id,
            role: x.role,
            created_at: x.created_at,
            is_active: x.is_active,
            is_subscribe: x.is_subscribe,
          };
        })
      );
    }
  };

  const regenerateActivationMail = async (data: {
    email: string;
  }): Promise<void> => {
    await dispatchRegenerateActivationMail(store, data);
  };

  const createCompanyUser = async (
    form: Omit<CompanyUserFormData, 'isSubscribe'>
  ): Promise<void> => {
    await dispatchCreateCompanyUser(store, {
      payload: {
        first_name: form.firstName,
        last_name: form.lastName,
        middle_name: form.middleName,
        email: form.email,
        departments: form.departments.map((d) => d.uuid),
        position: form.position,
        phone: form.phone,
        role_id: form.roleId,
      },
      companyId: readCompany(store)['uuid'],
    });
    await getCompanyUser();
  };

  const deleteCompanyUser = async (email: string): Promise<void> => {
    await dispatchDeleteCompanyUser(store, {
      companyId: readCompany(store)['uuid'],
      email: email,
    });
    await getCompanyUser();
  };

  const updateCurrentUser = async (): Promise<void> => {
    isLoading.value = true;
    try {
      await dispatchUpdateCurrentUser(store, {
        first_name: user.first_name,
        last_name: user.last_name,
        middle_name: user.middle_name,
        email: user.email,
        phone: user.phone,
        position: user.position,
        is_subscribe: user.is_subscribe,
        departments: user.departments.map((x) => x.uuid),
      });
      await dispatchGetCurrentUser(store);
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  const updateUser = async (
    userId: string,
    form: Omit<CompanyUserFormData, 'email'>
  ) => {
    isLoading.value = true;
    try {
      await dispatchUpdateCompanyUser(store, {
        companyId: readCompany(store)['uuid'],
        userId: userId,
        firstName: form.firstName,
        lastName: form.lastName,
        middleName: form.middleName,
        position: form.position,
        phone: form.phone,
        is_subscribe: form.isSubscribe,
        departments: form.departments,
        roleId: form.roleId,
      });
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
    if (userId === readUserProfile(store).uuid) {
      await dispatchGetCurrentUser(store);
    }
  };

  return {
    isLoading,
    user,
    users,
    getCompanyUser,
    regenerateActivationMail,
    createCompanyUser,
    updateCurrentUser,
    deleteCompanyUser,
    updateUser,
  };
};
